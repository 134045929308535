import Box from "common/components/Box";
import Button from "common/components/Button";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import ReactPlayer from "react-player";
import Slide from "@mui/material/Slide";
import StartRoundedIcon from "@mui/icons-material/StartRounded";
import Text from "common/components/Text";
import styled from "styled-components";
import React, { useState } from "react";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import { green, orange } from "@mui/material/colors";
import { useWindowSize } from "common/hooks/useWindowSize";

import SectionWrapper, { SectionWrapperAlt } from "./hero.style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VideoDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"What is the Employee Retention Credit?"}
      </DialogTitle>
      <DialogContent>
        <ReactPlayer
          playing
          controls={true}
          width="100%"
          height="350px"
          url="https://www.youtube-nocookie.com/embed/U653NdG8bfY"
        />
      </DialogContent>
      <DialogActions>
        <Button
          title="Close"
          variant="textButton"
          autoFocus
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

const Hero = ({ id }) => {
  const { width } = useWindowSize();
  const [videoIsOpen, setVideoIsOpen] = useState(false);

  //const result = 'test';
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        HERO_ITEMS {
          title
          text
          playButton {
            publicURL
          }
          videoCard {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          imageBg {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          imageBgMobile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          people {
            title
            personImage {
              publicURL
            }
            credit
            imageAsContent {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { title, text, image, imageBg, imageBgMobile, people } =
    data.ertCreditJson.HERO_ITEMS;

  const handleClose = () => {
    setVideoIsOpen(false);
  };
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);

  React.useEffect(() => {
    let interval = setInterval(async () => {
      let resp = await fetch(`/api/calculator-results/${id}`);
      let data = await resp.json();
      if (data.status !== 200) {
        console.log("Polling for results!");
      } else {
        setResult(data);
        clearInterval(interval);
        console.log("Got a result! Ending polling!");
      }
    }, 2000);
  }, []);

  return (
    <>
      <SectionWrapper>
        <Container width="1460px">
          {width > 568 ? (
            <GatsbyImage
              src={
                (imageBg !== null) | undefined
                  ? imageBg.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          ) : (
            <GatsbyImage
              src={
                (imageBgMobile !== null) | undefined
                  ? imageBgMobile.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          )}
        </Container>
      </SectionWrapper>
      <SectionWrapperAlt>
        <Container width="1420px" className="hero-content">
          <div className="content-wrapper">
            {result && (
              <div className="statistics-wrapper">
                <Heading as="h1" content={`Congratulations ${result.name}!`} />
                <Box className="result-box">
                  <Text
                    content={
                      result
                        ? Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "usd",
                          }).format(result.refund) + " is a very nice return!"
                        : ""
                    }
                    className="result-text"
                  />
                </Box>
              </div>
            )}
            {!result && (
              <Alert sx={{ border: "1px solid" }} severity="info">
                <AlertTitle>Hold on, we are crunching the numbers</AlertTitle>
                <CircularProgress /> This takes a second or two...
              </Alert>
            )}
            <VideoDialog open={videoIsOpen} handleClose={handleClose} />
            <div className="button-wrapper">
              <Button
                title="Get Started Today"
                variant="textButton"
                onClick={() => window.location.replace("/new-customer")}
                icon={<StartRoundedIcon />}
                iconPosition="left"
                className="learn-btn"
              />

              <Button
                title="Learn More"
                variant="altTextButton"
                icon={<PlayCircleRoundedIcon />}
                iconPosition="left"
                onClick={() => setVideoIsOpen(true)}
              />
            </div>
          </div>
          <div className="image">
            <GatsbyImage
              src={
                (image !== null) | undefined
                  ? image.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Image"
            />
            {people.map((person, index) => {
              return (
                <>
                  {person.imageAsContent === null ? (
                    <div key={index} className={`person-${index + 1}`}>
                      <img
                        className="person-img"
                        src={person.personImage.publicURL}
                        alt={person.title}
                      />
                      <div className="text-container">
                        <Text className="title" content={person.title} />
                        <p className="credit">
                          Credit Amount: <span>{person.credit}</span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div key={index} className={`person-${index + 1}`}>
                      <img
                        className="person-img"
                        src={person.personImage.publicURL}
                        alt={person.title}
                      />
                      <div className="text-container">
                        <img
                          className="text-img"
                          src={person.imageAsContent.publicURL}
                          alt={person.imageAsContent}
                        />
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </Container>
      </SectionWrapperAlt>
    </>
  );
};

export default Hero;
