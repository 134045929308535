import * as React from "react";
import Customers from "containers/Customers";
import Faq from "containers/Faq";
import Features from "containers/Features";
import HeroResults from "containers/HeroResult";
import HowItWorks from "containers/HowItWorks";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import WhyUs from "containers/WhyUs";
import { Router } from "@reach/router";

const HomePage = ({ location }) => {
  return (

    <Layout location={location.pathname}>
      <Seo title="Calculator Results" />
      <Router basepath="/">
        <HeroResults path="calculator-results/:id" />
      </Router>
      <Features />
      <Customers />
      <HowItWorks />
      <WhyUs />
      <Faq />
    </Layout>


  );
};

export default HomePage;
